export class QuizSetup {
}

//export class EmployeeQuizMessage {
//  public QuizTitle: string = "Untitled form";
//  public QuizDescription: string = "";
//  public CreatedBy: string;
//  public CreatedOn: Date;
//  public UpdatedBy: string;
//  public UpdatedOn: Date;
//  public FranchiseID: number;
//  // public isOnClock: number;
//  public QuizPassingPercent: number = 90;
//}

export class QuestionnaireBasic {
  public QuestionnaireTypeID: number = 0;
  public QuestionnaireID: number = 0;
  public QuestionnaireGUID: string = "";
  public Title: string = "";
  public Description: string = "";
  public PassingPercent: number = 90;
  public IsPublish: boolean = false;
  public QuestionList: Array<QuestionListModel> = new Array<QuestionListModel>();
}


export class QuestionnaireModel extends  QuestionnaireBasic {
  public QuestionList: Array<QuestionListModel> = new Array<QuestionListModel>();

}

export class OptionsModel {
  public OptionID: number;
  public OptionError: boolean = false;
  public OptionDescription: string = '';
  public IsCorrectAnswer: boolean = false;

}

export class QuestionListModel {
  public QuestionID: number = 0;
  public QuestionDescription: string = '';
  public DisplayOrder: number = 1;
  public QuestionType: number = -1;
  public LastOptionIndex: number = 1;
  public QuestionError: boolean = false;
  public IsRequired: boolean = false;
  public IsAnswerMissing: boolean = false;
  public Options: Array<OptionsModel> = new Array<OptionsModel>();
}
